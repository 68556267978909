export const EraserIcon = () => (
  <svg
    fill="#000000"
    width="20px"
    height="20px"
    viewBox="0 0 256 256"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M38.032 127.602l76.405-78.588c3.074-3.162 9.157-5.737 13.573-5.737h88.392a7.958 7.958 0 0 1 7.966 7.996l-.243 63.237c-.017 4.412-2.563 10.53-5.686 13.655l-74.118 74.176c-3.121 3.124-9.239 5.659-13.66 5.659H40.46c-4.425 0-8.005-3.577-8.005-7.99v-58.682c0-4.414 2.497-10.558 5.577-13.726zM120.002 144h-63.98c-4.405 0-7.997 3.578-7.997 7.993v32.09a7.992 7.992 0 0 0 7.998 7.993h63.98c4.405 0 7.997-3.578 7.997-7.993v-32.09a7.992 7.992 0 0 0-7.998-7.993zm29.638-6.036c-3.115 3.14-5.64 9.268-5.64 13.685v21.72c0 4.418 2.476 5.42 5.542 2.227l53.585-55.823c3.06-3.188 5.542-9.36 5.542-13.771V80.459c0-1.105-.63-1.365-1.41-.578l-57.62 58.083zm-18.046-78.59c-1.66.03-3.966 1-5.143 2.16l-61.065 60.197c-3.146 3.102-2.114 5.616 2.306 5.616h60.119c4.42 0 10.559-2.506 13.71-5.596l63.519-62.277c.788-.773.525-1.384-.577-1.365l-72.869 1.266z"
      fill-rule="evenodd"
    />
  </svg>
);

export const HamburgerIcon = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 1920 1920"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1920 1468.412v112.94H0v-112.94h1920Zm0-564.706v112.941H0V903.706h1920ZM1920 339v112.941H0V339h1920Z"
      fill-rule="evenodd"
    />
  </svg>
);

export const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20px"
    height="20px"
    viewBox="0 0 24 24"
  >
    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
  </svg>
);

export const ArrowRight = () => (
  <svg
    width="10px"
    height="10px"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M256 120.768L306.432 64 768 512l-461.568 448L256 903.232 659.072 512z" />
  </svg>
);

export const ArrowLeft = () => (
  <svg
    width="10px"
    height="10px"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M768 903.232l-50.432 56.768L256 512l461.568-448 50.432 56.768L364.928 512z" />
  </svg>
);

export const BinIcon = () => (
  <svg
    height="20px"
    width="20px"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    enable-background="new 0 0 50 50"
  >
    <path
      d="M10.289,14.211h3.102l1.444,25.439c0.029,0.529,0.468,0.943,0.998,0.943h18.933
	c0.53,0,0.969-0.415,0.998-0.944l1.421-25.438h3.104c0.553,0,1-0.448,1-1s-0.447-1-1-1h-3.741c-0.055,0-0.103,0.023-0.156,0.031
	c-0.052-0.008-0.1-0.031-0.153-0.031h-5.246V9.594c0-0.552-0.447-1-1-1h-9.409c-0.553,0-1,0.448-1,1v2.617h-5.248
	c-0.046,0-0.087,0.021-0.132,0.027c-0.046-0.007-0.087-0.027-0.135-0.027h-3.779c-0.553,0-1,0.448-1,1S9.736,14.211,10.289,14.211z
	 M21.584,10.594h7.409v1.617h-7.409V10.594z M35.182,14.211L33.82,38.594H16.778l-1.384-24.383H35.182z"
    />
    <path
      d="M20.337,36.719c0.02,0,0.038,0,0.058-0.001c0.552-0.031,0.973-0.504,0.941-1.055l-1.052-18.535
	c-0.031-0.552-0.517-0.967-1.055-0.942c-0.552,0.031-0.973,0.504-0.941,1.055l1.052,18.535
	C19.37,36.308,19.811,36.719,20.337,36.719z"
    />
    <path
      d="M30.147,36.718c0.02,0.001,0.038,0.001,0.058,0.001c0.526,0,0.967-0.411,0.997-0.943l1.052-18.535
	c0.031-0.551-0.39-1.024-0.941-1.055c-0.543-0.023-1.023,0.39-1.055,0.942l-1.052,18.535C29.175,36.214,29.596,36.687,30.147,36.718
	z"
    />
    <path
      d="M25.289,36.719c0.553,0,1-0.448,1-1V17.184c0-0.552-0.447-1-1-1s-1,0.448-1,1v18.535
	C24.289,36.271,24.736,36.719,25.289,36.719z"
    />
  </svg>
);

export const DownloadIcon = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M550.4 682.816L778.688 448l53.312 54.848L512 832 192 502.848 245.312 448 473.6 682.816V64h76.8v618.816zM192 883.2h640V960H192v-76.8z" />
  </svg>
);
