import React, { useState, useRef, useEffect } from "react";
import { CanvasMenu } from "./CanvasMenu";
import { BinIcon, CloseIcon, DownloadIcon } from "./Icons";
interface CanvasProps {
  onClose: () => void;
}

export const Canvas: React.FC<CanvasProps> = ({ onClose }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const ctxRef = useRef<CanvasRenderingContext2D | null>(null);
  const canvas = canvasRef.current;
  const ctx = ctxRef.current;
  const [isDrawing, setIsDrawing] = useState(true);
  const [lineWidth, setLineWidth] = useState(1);
  const [lineColor, setLineColor] = useState("black");
  const [lineOpacity, setLineOpacity] = useState(1);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");
    if (ctx) {
      ctx.lineCap = "round";
      ctx.lineJoin = "round";
      ctx.globalAlpha = lineOpacity;
      ctx.strokeStyle = lineColor;
      ctx.lineWidth = lineWidth;
      ctxRef.current = ctx;
    }
  }, [lineColor, lineOpacity, lineWidth]);

  useEffect(() => {
    const resizeCanvas = () => {
      const canvas = canvasRef.current;
      const container = canvas?.parentElement;
      if (container && canvas) {
        const imageData = ctxRef.current?.getImageData(
          0,
          0,
          canvas.width,
          canvas.height
        );
        canvas.width = container.clientWidth * 1;
        canvas.height = container.clientHeight * 1;
        if (imageData) {
          ctxRef.current?.putImageData(imageData, 0, 0);
        }
      }
    };

    resizeCanvas();

    window.addEventListener("resize", resizeCanvas);

    return () => {
      window.removeEventListener("resize", resizeCanvas);
    };
  }, []);

  const background = (ctx: CanvasRenderingContext2D) => {
    const canvas = canvasRef.current;
    if (canvas) {
      ctx.fillStyle = "beige";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    }
  };

  const startDrawing = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    if (!canvas) return;
    const rect = canvas.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;
    if (ctx) {
      ctx.beginPath();
      ctx.moveTo(offsetX, offsetY);
      setIsDrawing(true);
    }
  };

  const endDrawing = () => {
    setIsDrawing(false);
  };

  const draw = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    if (!isDrawing) return;
    if (!canvas) return;
    const rect = canvas.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;
    if (ctx) {
      ctx.lineTo(offsetX, offsetY);
      ctx.stroke();
    }
  };

  const eraseCanvas = () => {
    if (!canvas) return;
    if (ctx) {
      ctx.strokeStyle = "beige";
      ctx.lineWidth = 50;
    }
  };

  const clearCanvas = () => {
    if (!canvas) return;
    if (ctx) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      background(ctx);
    }
  };

  const downloadCanvas = () => {
    const canvas = canvasRef.current;
    if (canvas && ctxRef.current) {
      // Temporarily set the background color to beige before saving
      const tempCanvas = document.createElement("canvas");
      tempCanvas.width = canvas.width;
      tempCanvas.height = canvas.height;
      const tempCtx = tempCanvas.getContext("2d");

      if (tempCtx) {
        // Draw beige background
        tempCtx.fillStyle = "beige";
        tempCtx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);

        // Draw the current canvas on top
        tempCtx.drawImage(canvas, 0, 0);

        // Save the temporary canvas as an image
        const link = document.createElement("a");
        link.href = tempCanvas.toDataURL("image/jpg");
        link.download = "canvas-image.jpg";
        link.click();
      }
    }
  };

  return (
    <div className="canvas-container">
      <CanvasMenu
        lineColor={lineColor}
        setLineColor={setLineColor}
        lineWidth={lineWidth}
        setLineWidth={setLineWidth}
        lineOpacity={lineOpacity}
        setLineOpacity={setLineOpacity}
        eraseCanvas={eraseCanvas}
      />
      <div className="close-canvas icon" onClick={onClose}>
        <CloseIcon />
      </div>
      <div className="clear-canvas icon" onClick={clearCanvas}>
        <BinIcon />
      </div>
      <div className="download-canvas icon" onClick={downloadCanvas}>
        <DownloadIcon />
      </div>
      <canvas
        ref={canvasRef}
        onMouseDown={startDrawing}
        onMouseUp={endDrawing}
        onMouseMove={draw}
      ></canvas>
    </div>
  );
};
