// Components
import { Navbar } from "./components/Navbar";
import { Cards } from "./components/Cards";
import { MusicPlayer } from "./components/MusicPlayer";
import { Canvas } from "./components/Canvas";

// Styling & Assets
import "../src/_app.scss";
import { useState } from "react";
import PropTypes from "prop-types";

export const App = () => {
  const [canvasOpen, setCanvasOpen] = useState(false);

  const openCanvas = () => {
    setCanvasOpen(true);
  };

  const closeCanvas = () => {
    setCanvasOpen(false);
  };

  return (
    <div className="app-container">
      <Navbar />
      <div className="open-canvas" onClick={openCanvas}>
        Draw!
      </div>
      {canvasOpen && <Canvas onClose={closeCanvas} />}
      <Cards />
      <MusicPlayer />
    </div>
  );
};

App.propTypes = {
  closeCanvas: PropTypes.func,
};

export default App;
