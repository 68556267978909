import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Data
import { useQuery } from "@apollo/client";
import { content } from "../gql/Query";

// Hooks
import { setMixes } from "../store/reducers/mediaReducer";

// Components
import { Card } from "./Card";

export const Cards = () => {
  const { data, refetch } = useQuery(content);
  const [genreState, setGenreState] = useState("");
  const dispatch = useDispatch();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const genreParam = urlSearchParams.get("genre");

  useEffect(() => {
    if (data?.mixes) {
      dispatch(setMixes(data?.mixes));
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (genreParam !== genreState) {
      refetch();
    }
  }, [genreParam, genreState, refetch]);

  const filteredMixes = genreParam
    ? data?.mixes?.filter((mix: any) =>
        mix.genre.toLowerCase().includes(genreParam)
      )
    : data?.mixes;

  const handleGenreClick = (genre: string) => {
    const url = new URL(window.location.href);
    url.searchParams.set("genre", genre.toLocaleLowerCase());
    window.history.pushState({}, "", url.toString());
    setGenreState(genre);
    window.scrollTo(0, 0);
  };

  const removeGenreParam = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete("genre");
    window.history.pushState({}, "", url.toString());
    setGenreState("");
    window.scrollTo(0, 0);
  };

  const createGenreFilter = () => {
    return (
      <div className="genre-filter" onClick={removeGenreParam}>
        <h4>{genreParam?.toLocaleUpperCase()}</h4>
        <h4>x</h4>
      </div>
    );
  };

  return (
    <div className="content-container">
      <div className="cards-container">
        {genreParam ? createGenreFilter() : null}
        {filteredMixes?.map((mix: any, index: number) => (
          <Card
            mix={mix}
            key={index}
            cardIndex={index}
            handleGenreClick={handleGenreClick}
          />
        ))}
      </div>
    </div>
  );
};
