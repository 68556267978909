import React from "react";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  setAudioRef,
  setAudioSource,
  setAudioTitle,
  setSelectedCard,
  toggleIsPlaying,
} from "../store/reducers/mediaReducer";

// Fade
import Fade from "react-reveal/Fade";

// Icons
import { BiPlay, BiPause } from "react-icons/bi";

interface CardProps {
  mix: {
    image: {
      url: string;
    };
    audio: {
      url: string;
    };
    title: string;
    date: string;
    genre: string;
  };
  cardIndex: number;
  handleGenreClick?: any;
}

export const Card: React.FC<CardProps> = ({
  mix,
  cardIndex,
  handleGenreClick,
}) => {
  const dispatch = useDispatch();

  const { audioRef, isPlaying, selectedCard } = useSelector(
    (state: any) => state.media
  );

  const { image, audio, title, date, genre } = mix;

  const handlePlayPause = () => {
    if (selectedCard === cardIndex) {
      dispatch(toggleIsPlaying());
    } else {
      dispatch(setAudioTitle(title));
      dispatch(setAudioSource(audio.url));
      dispatch(setAudioRef(audioRef));
      dispatch(setSelectedCard(cardIndex));
      if (!isPlaying) {
        dispatch(toggleIsPlaying());
      }
    }
  };

  const genres = genre.split(",").map((g) => g.trim());

  return (
    <Fade duration={1000}>
      <div className="card-container" key={cardIndex}>
        <div className="card-image" onClick={handlePlayPause}>
          <img src={image.url} alt="Mix" />
          {isPlaying && selectedCard === cardIndex ? (
            <BiPause className="play-pause" />
          ) : (
            <BiPlay className="play-pause" />
          )}
        </div>
        <div className="card-text">
          <p className="card-date">{date}</p>
          <h5 className="card-title">{title}</h5>
          <div className="card-genre">
            {genres.map((genre, index) => (
              <p
                key={index}
                className="genre-item"
                onClick={() => handleGenreClick(genre)}
              >
                {genre}
              </p>
            ))}
          </div>
        </div>
      </div>
    </Fade>
  );
};
