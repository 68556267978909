import React, { useState } from "react";
import { ArrowLeft, ArrowRight, EraserIcon } from "./Icons";

interface CanvasMenuProps {
  lineColor: string;
  setLineColor: any;
  lineWidth: number;
  setLineWidth: any;
  lineOpacity: number;
  setLineOpacity: any;
  eraseCanvas: () => void;
}

export const CanvasMenu: React.FC<CanvasMenuProps> = ({
  lineColor,
  setLineColor,
  lineWidth,
  setLineWidth,
  lineOpacity,
  setLineOpacity,
  eraseCanvas,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const openMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="outer-menu">
      {menuOpen && (
        <div className="menu-container">
          <div className="brush-color">
            <h4>Brush Color</h4>
            <input
              type="color"
              defaultValue={lineColor}
              onChange={(e: any) => setLineColor(e.target.value)}
            />
          </div>
          <div className="brush-width">
            <h4>Brush Width</h4>
            <input
              type="range"
              min="1"
              max="50"
              defaultValue={lineWidth}
              onChange={(e: any) => setLineWidth(e.target.value)}
            />
          </div>
          <div className="brush-opacity">
            <h4>Brush Opacity</h4>
            <input
              defaultValue={lineOpacity * 100}
              type="range"
              min="1"
              max="100"
              onChange={(e: any) => setLineOpacity(e.target.value / 100)}
            />
          </div>
          <div className="erase-brush" onClick={eraseCanvas}>
            <h4>Eraser</h4>
            <EraserIcon />
          </div>
        </div>
      )}
      <div className="menu-icons icon" onClick={openMenu}>
        {menuOpen ? <ArrowLeft /> : <ArrowRight />}
      </div>
    </div>
  );
};
